<template>
  <div class="">
    <label for="button-text" class="editor-label">{{ label }}</label>
    <div class="editor-select-color">
        <!-- Color Picker -->
        <input 
          title="Color Picker"
          type="color"
          v-model="internalColor"
          class="ai-color-circle"
        />

        <!-- Hex Color Input -->
        <input 
          type="text" 
          v-model="internalColor"
          title="Input Color" 
          class="editor-color-input w-100"
        />

        <!-- Opacity Input -->
        <div class="d-flex gap editor-opacity" v-if="isOpacity">
          <input 
            type="number" 
            max="100"
            v-model.number="internalOpacity" 
            title="Opacity" 
            class="editor-color-input w-100"
          />
          <span>%</span>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,  // For color
      default: '#FFFFFF'
    },
    opacity: {
      type: [Number,String],  // For opacity as percentage
      default: 100
    },
    label: {
      type: String,
      default: 'Color:'
    },
    isOpacity:{
      type:Boolean,
      default:true
    }
  },
  data() {
    return {
      internalColor: this.value,
      internalOpacity: this.opacity
    };
  },
  watch: {
    // Watch the prop changes and update the internal values
    value(newValue) {
      this.internalColor = newValue;
    },
    opacity(newOpacity) {
      this.internalOpacity = newOpacity;
    },
    // Emit the changes to parent component
    internalColor(newColor) {
      this.$emit('input', newColor);
    },
    internalOpacity(newOpacity) {
      this.$emit('update:opacity', newOpacity);
    }
  }
};
</script>

<style scoped>
.editor-select-color {
  border-radius: 6px;
  display: flex;
  align-items: center;
  background: #F7F7F7;
  padding: 6px;
  gap: 8px;
}
input[type="color"] {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #D2D8E0;
  overflow: hidden;
  padding: 0;
}
input[type="color"]::-webkit-color-swatch {
  border-radius: 4px;
  border:none;
  width: 20px;
  height: 20px;
  padding: 0;
}
input[type="color"]::-webkit-color-swatch-wrapper {
  border-radius: 4px;
  width: 20px;
  height: 20px;
  border:none;
  padding: 0;
}
.editor-color-input:focus-visible {
  border: none;
  outline: none;
}
.editor-color-input {
  outline: none;
  border: none;
  background: transparent;
}
.editor-opacity {
  border-left: 1px solid white;
  padding-left: 8px;
}
.editor-select-color:focus-within {
  box-shadow: 0px 0px 1px 1px var(--secondary-color);
}
</style>
